import { HStack, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import LeftSideBar from "../../components/LeftSideBar";
import Dashboard from "./Dashboard";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { GoDatabase } from "react-icons/go";
const LeftBarData = [
  {
    name: "Dashboard",
    link: "/manage",
  },
  {
    name: "Users",
    link: "/manage/users",
    Icon:AiOutlineUsergroupAdd
  },
  {
    name: "Data",
    link: "/manage/data",
    Icon:GoDatabase
  },
];
const SuperAdmin = () => {
  const { pathname } = useLocation();
  return (
    <HStack minH={"100vh"} gap={"1.8rem"} align={"start"}>
      <LeftSideBar LeftBarData={LeftBarData} />
      <VStack w={"85%"} pt={"3rem"}>
      {pathname === "/manage" && <Dashboard />}
        <Outlet />
      </VStack>
    </HStack>
  );
};

export default SuperAdmin;
