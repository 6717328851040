import { Tr, Td, Text, useToast } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { DownloadUserFile } from "../utils/api";
import { AppContext } from "../context/AppContext";
import axios from 'axios';
const ClientDataCard = ({
  email,
  clientFileName,
  date,
  NumberOfRowsClient,
  NumberOfRowsAdmin,
  status,
  clientFileLink,
  superAdminFileLink
}) => {
  const { Token1, handleLogout } = useContext(AppContext);
  const toast = useToast();
  useEffect(()=>{
    console.log(NumberOfRowsClient,'hello world')
  },[])
  const handleDownload = (downloadLink) => {
    window.open(downloadLink,'_blank');
    // DownloadUserFile(
    //   Token1,
    //   id,
    //   who === "client" ? clientFileName : superAdminFileName,
    //   who
    // )
    //   .then((res) => {
    //     window.open(res.data.fileLink,'_blank');
    //     toast({
    //       status: "success",
    //       duration: 1000,
    //       title: `${
    //         who === "client" ? clientFileName : superAdminFileName
    //       } download successfully`,
    //       isClosable: true,
    //     });
    //   })
    //   .catch((err) => {
    //     handleLogout(err);
    //   });
  };
  return (
    <Tr>
      <Td
        _hover={{ fontWeight: 600 }}
        color={"#0158DE"}
        cursor={"pointer"}
        onClick={() => handleDownload(clientFileLink)}
      >
        <Text w={"13rem"} style={{ textWrap: "wrap" }}>
          {clientFileName}
        </Text>
      </Td>
      <Td>{NumberOfRowsClient==0?'updating...':NumberOfRowsClient}</Td>
      <Td>{date}</Td>
      <Td>{email}</Td>
      <Td>
        {NumberOfRowsAdmin || 0}/{NumberOfRowsClient==0?'updating...':NumberOfRowsClient}
      </Td>
      <Td>
        <Text
          w={"9rem"}
          cursor={status=='Ready to download' ? "pointer" : "not-allowed"}
          _hover={status=='Ready to download' ? { fontWeight: 600 } : {}}
          color={status=='Ready to download' ? "#0158DE" : "grey"}
          onClick={() =>
            status=='Ready to download' ? handleDownload(superAdminFileLink) : ""
          }
        >
          {status}
        </Text>
      </Td>
    </Tr>
  );
};

export default ClientDataCard;
