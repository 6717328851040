import React, { useContext, useEffect, useState } from "react";
import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import DataCard from "../components/DataCard";
import { AppContext } from "../../../context/AppContext";
import {
  SuperAdminAllCompanies,
  SuperAdminUploadFile,
  SuperAdminUserAllData,
} from "../../../utils/api";
import styles from "./Data.module.css";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
const Data = () => {
  const [data, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [compnaies, setCompanies] = useState([]);
  const toast = useToast();
  const { Token2, handleLogout } = useContext(AppContext);
  const [filter, setFilter] = useState("all");
  const [sort, setSort] = useState({ name: "createdAt", value: -1 });
  const [currentDataId, setCurrentDataId] = useState("");
  const [dateSort, setDateSort] = useState("down");
  const [Loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  useEffect(() => {
    SuperAdminAllCompanies(Token2)
      .then((res) => {
        setCompanies(res.data.data);
      })
      .catch((err) => {
        handleLogout(err);
      });
  }, []);
  useEffect(() => {
    setLoading(true);
    SuperAdminUserAllData(Token2, filter, sort.name, sort.value)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        handleLogout(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filter, sort]);
  const handleModal = (id) => {
    console.log(id);
    setCurrentDataId(id);
    onOpen();
  };
  const handleUpload = (event) => {
    let file = event.target.files[0];
    let formData = new FormData();
    formData.append("excel", file);
    if (file != undefined) {
      setUploadLoading(true);
      SuperAdminUploadFile(Token2, formData, currentDataId)
        .then((res) => {
          setUploadLoading(false);
          onClose();
          toast({
            status: "success",
            title: res.data.message,
            isClosable: true,
            duration: 2000,
          });
          let currentData = data;
          currentData = currentData.map((el) => {
            if (el._id == currentDataId) {
              el.NumberOfRowsAdmin = res.data.NumberOfRowsAdmin;
              el.superAdminFileName = res.data.superAdminFileName;
            }
            return el;
          });
          setData([...currentData]);
        })
        .catch((err) => {
          setUploadLoading(false);
          handleLogout(err);
        });
    }
  };
  const handleSort = (name, value, position) => {
    setSort({ ...sort, name, value });
    setDateSort(position);
  };
  return (
    <VStack position={"relative"} w={"100%"}>
      <HStack w={"100%"}>
        <Select
          w={"22%"}
          fontSize={"0.9rem"}
          h={"2rem"}
          onChange={(event) => setFilter(event.target.value)}
          placeholder="Select Company"
          // h={'auto'}
          // padding={'0.55rem 0.5rem !important'}
        >
          {compnaies?.map((el) => (
            <option key={el} value={el}>
              {el}
            </option>
          ))}
        </Select>
      </HStack>
      <TableContainer w={"100%"} maxH={"75vh"} overflowY={"auto"}>
        <Table size="sm">
          <Thead position={"sticky"} top={"0"} background={"white"} zIndex={10}>
            <Tr>
              <Th
                textTransform={"none"}
                bgColor={"#baa8ff"}
                fontSize={"0.7rem"}
              >
                <Text w={"13rem"}>File Name</Text>
              </Th>
              <Th
                textTransform={"none"}
                bgColor={"#baa8ff"}
                fontSize={"0.7rem"}
              >
                Rows
              </Th>
              <Th
                textTransform={"none"}
                bgColor={"#baa8ff"}
                fontSize={"0.7rem"}
              >
                <HStack w={"8rem"}>
                  <Text>
                    {" "}
                    Upload date <br />
                    (IST)
                  </Text>
                  {dateSort == "down" ? (
                    <AiOutlineArrowDown
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleSort("createdAt", 1, "up")}
                    />
                  ) : (
                    <AiOutlineArrowUp
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleSort("createdAt", -1, "down")}
                    />
                  )}
                </HStack>
              </Th>
              <Th
                textTransform={"none"}
                bgColor={"#baa8ff"}
                fontSize={"0.7rem"}
              >
                Uploaded by
              </Th>
              {/* <Th textTransform={"none"} bgColor={"#45c584"} color={"black"}fontSize={"0.7rem"}>
                Download
              </Th> */}
              <Th
                textTransform={"none"}
                fontWeight={500}
                bgColor={"#45c584"}
                color={"black"}
                fontSize={"0.7rem"}
              >
                Download <br />
                count
              </Th>
              <Th
                textTransform={"none"}
                fontWeight={500}
                bgColor={"#45c584"}
                color={"black"}
                fontSize={"0.7rem"}
              >
                Upload <br /> processed
              </Th>
              <Th
                textTransform={"none"}
                fontWeight={500}
                bgColor={"#45c584"}
                color={"black"}
                fontSize={"0.7rem"}
              >
                Rows <br />
                (admin/client)
              </Th>
              <Th
                textTransform={"none"}
                fontWeight={500}
                bgColor={"#45c584"}
                color={"black"}
                fontSize={"0.7rem"}
              >
                Client View <br />
                Status
              </Th>
            </Tr>
          </Thead>
          <Tbody bgColor={"#F8F8F8"}>
            {!Loading &&
              data?.map((myElement) => (
                <DataCard
                  key={myElement._id}
                  email={myElement.userId.email}
                  clientFileName={myElement.clientFileName}
                  NumberOfRowsClient={myElement.NumberOfRowsClient}
                  superAdminFileName={myElement.superAdminFileName}
                  NumberOfDownloads={myElement.NumberOfDownloads}
                  date={myElement.date}
                  id={myElement._id}
                  NumberOfRowsAdmin={myElement.NumberOfRowsAdmin}
                  status={myElement.status}
                  handleModal={handleModal}
                />
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      {Loading && (
        <Spinner
          position={"absolute"}
          top={"50vh"}
          left={"50%"}
          color="grey"
          size="lg"
        />
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p={"2rem 1.2rem"}>
          {<ModalCloseButton />}
          <ModalBody>
            <VStack w={"100%"} justify={'center'} h={"10rem"}>
              {uploadLoading ? (
                <Spinner
                  speed="0.35s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size={"xl"}
                  thickness="4.5px"
                />
              ) : (
                <>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    id="my_excel"
                    // multiple
                    name="excel"
                    onChange={handleUpload}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                  <label className={styles.upload} htmlFor="my_excel">
                    Upload File
                  </label>
                </>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Data;
