import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./Login";
import SuperAdminAuth from "../components/SuperAdminAuth";
import SuperAdmin from "./SuperAdmin";
import Users from "./SuperAdmin/Users";
import Data from "./SuperAdmin/Data";
import UserAuth from "../components/UserAuth";
import ClientData from "./ClilentData";
import ClientDashboard from "./ClientDashboard";
import ClientSettings from "./ClientSettings/ClientSettings";
const AllPages = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <UserAuth>
            <ClientDashboard />
          </UserAuth>
        }
      >
        <Route path="data" element={<ClientData />} />
        <Route path="setting" element={<ClientSettings />} />
        {/* <Route path="" element={<Users />} /> */}
      </Route>
      <Route
        path="/manage"
        element={
          <SuperAdminAuth>
            <SuperAdmin />
          </SuperAdminAuth>
        }
      >
        <Route path="users" element={<Users />} />
        <Route path="data" element={<Data />} />
      </Route>
    </Routes>
  );
};

export default AllPages;
