import { Tr, Td, Text, useToast, Select } from "@chakra-ui/react";
import React, { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import {
  SuperAdminDownloadFile,
  SuperAdminUpdateStatus,
  SuperAdminUploadFile,
} from "../../../utils/api";

const DataCard = ({
  email,
  clientFileName,
  date,
  id,
  NumberOfDownloads,
  NumberOfRowsClient,
  NumberOfRowsAdmin,
  handleModal,
  status,
  superAdminFileName,
}) => {
  const { Token2, handleLogout } = useContext(AppContext);
  const toast = useToast();
  const handleDownload = () => {
    SuperAdminDownloadFile(Token2, id, clientFileName)
      .then((res) => {
        window.open(res.data.fileLink,'_blank');
        toast({
          status: "success",
          duration: 1000,
          title: `${clientFileName} download successfully`,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: err.response?.data?.message
            ? err.response?.data.message
            : err.message,
          status: "error",
          duration: 2500,
          isClosable: true,
          position: "bottom",
        });
      });
  };
  const handleStatus = (event) => {
    const { value } = event.target;
    if (!superAdminFileName&&value==='Ready to download') {
      toast({
        status: "warning",
        title: 'first upload the file, then select "ready to download"',
        isClosable: true,
        duration: 1500,
      });
      event.target.value = status;
      return;
    }
    SuperAdminUpdateStatus(Token2, { status: value }, id)
      .then((res) => {
        console.log(res, "checking updated");
      })
      .catch((err) => {
        handleLogout(err);
        event.target.value = status;
      });
  };
  return (
    <Tr fontSize={"0.7rem"}>
      <Td
        fontSize={"0.7rem"}
        _hover={{ fontWeight: 600 }}
        color={"#0158DE"}
        cursor={"pointer"}
        onClick={handleDownload}
        // maxW={'10%'}
        width={"13%"}
        style={{ textWrap: "wrap" }}
      >
        <Text w={"13rem"} style={{ textWrap: "wrap" }}>
          {clientFileName}
        </Text>
      </Td>
      <Td fontSize={"0.7rem"}>{NumberOfRowsClient}</Td>
      <Td fontSize={"0.7rem"}>{date}</Td>
      <Td fontSize={"0.7rem"}>{email}</Td>
      <Td fontSize={"0.7rem"}>{NumberOfDownloads}</Td>
      <Td fontSize={"0.7rem"}>
        <Text
          cursor={"pointer"}
          onClick={() => handleModal(id)}
          _hover={{ fontWeight: 600 }}
          color={"#0158DE"}
        >
          Upload
        </Text>
      </Td>
      <Td fontSize={"0.7rem"}>
        {NumberOfRowsAdmin}/{NumberOfRowsClient}
      </Td>
      <Td fontSize={"0.7rem"}>
        <Select
          w={"9.4rem"}
          defaultValue={status}
          fontSize={"0.7rem"}
          h={"1.5rem"}
          cursor={"pointer"}
          onChange={handleStatus}
          // onChange={}
          // padding={'0.55rem 0.5rem !important'}
        >
          <option value="Sent">Sent</option>
          <option value="Processing...">Processing...</option>
          <option value="Ready to download">Ready to download</option>
        </Select>
      </Td>
    </Tr>
  );
};

export default DataCard;
