import {
  Button,
  VStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Input,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import {
  SuperAdminCreateNewUser,
  SuperAdminDeleteUser,
  SuperAdminEditUser,
  SuperAdminGetAllUser,
} from "../../../utils/api";
import { AppContext } from "../../../context/AppContext";
import UsersCard from "../components/UsersCard";
const Users = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { Token2, handleLogout } = useContext(AppContext);
  const toast = useToast();
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const [currentData, setCurrentData] = useState({});
  const editFirstNameRef = useRef();
  const editLastNameRef = useRef();
  const editEmailRef = useRef();
  const editCompanyRef = useRef();
  const editPasswordRef = useRef();
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
  });
  useEffect(() => {
    SuperAdminGetAllUser(Token2)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        handleLogout(err);
      });
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({
      ...userInfo,
      [name]:
        name == "email" || name == "company" ? value
        ?.toLowerCase() : value,
    });
  };
  const handleFocus = (event) => {
    event.target.placeholder = "";
  };
  const handleBlur = (event, ph) => {
    event.target.placeholder = ph;
  };
  const handleEdit = (event) => {
    event.preventDefault();
    let postData = {
      email: editEmailRef.current.value,
      company: editCompanyRef.current.value?.toLowerCase(),
      username: `${editFirstNameRef.current.value} ${editLastNameRef.current.value}`,
      password: editPasswordRef.current.value,
    };
    SuperAdminEditUser(Token2, postData, currentUserId)
      .then((res) => {
        toast({
          status: "success",
          title: res.data.message,
          isClosable: true,
          duration: 2000,
          position: "top-right",
        });
        setEditModal(false);
        let updateData = data.map((el) => {
          if (el._id === currentUserId) {
            el = { ...el, ...postData };
          }
          return el;
        });
        setData([...updateData]);
      })
      .catch((err) => {
        handleLogout(err);
        // toast({
        //   title: err.response?.data?.message
        //     ? err.response?.data.message
        //     : err.message,
        //   status: "error",
        //   duration: 2500,
        //   isClosable: true,
        //   position: "bottom",
        // });
      });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let postData = {
      email: userInfo.email,
      company: userInfo.company,
      username: `${userInfo.firstName} ${userInfo.lastName}`,
    };
    SuperAdminCreateNewUser(Token2, postData)
      .then((res) => {
        postData = {
          ...postData,
          password: res.data.password,
          _id: res.data.id,
        };
        setData([...data, postData]);
        toast({
          status: "success",
          title: res.data.message,
          isClosable: true,
          duration: 2000,
          position: "top-right",
        });
        onClose();
      })
      .catch((err) => {
        handleLogout(err);
      });
  };
  const handleModals = (id, stat, el) => {
    setCurrentUserId(id);
    if (stat == "edit") {
      setEditModal(true);
      setCurrentData(el);
    } else if (stat == "delete") {
      setDeleteModal(true);
    }
  };
  const handleDelete = () => {
    SuperAdminDeleteUser(Token2, currentUserId)
      .then((res) => {
        toast({
          status: "success",
          isClosable: true,
          duration: 1500,
          title: res.data.message,
          position: "top-right",
        });
        let myData = data;
        myData = myData.filter((el) => el._id !== currentUserId);
        setData([...myData]);
        setDeleteModal(false);
      })
      .catch((err) => {
        handleLogout(err);
      });
  };
  return (
    <VStack w={"100%"} align={"end"}>
      <Button
        rounded={"0.18rem"}
        h={"auto"}
        p={"0.45rem"}
        w={"10.8rem"}
        bgColor={"#0158DE"}
        color={"white"}
        leftIcon={<IoMdAdd />}
        onClick={onOpen}
      >
        Add User
      </Button>
      <TableContainer w={"100%"} maxH={"75vh"} overflowY={"auto"}>
        <Table size="sm">
          <Thead position={"sticky"} top={"0"} background={"#DAD9DA"} zIndex={10}>
            <Tr>
              <Th textTransform={"none"} fontSize={"0.9rem"}>
                Company
              </Th>
              <Th textTransform={"none"} fontSize={"0.9rem"}>
                User Name
              </Th>
              <Th textTransform={"none"} fontSize={"0.9rem"}>
                Email
              </Th>
              <Th textTransform={"none"} fontSize={"0.9rem"}>
                Password
              </Th>
              <Th padding={"1rem"}></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody bgColor={"#F8F8F8"}>
            {data?.map((el) => (
              <UsersCard
                key={el._id}
                username={el.username}
                company={el.company}
                email={el.email}
                password={el.password}
                handleModals={handleModals}
                id={el._id}
                el={el}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {/* Delete user modal  */}
      <Modal isOpen={deleteModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <VStack p={"2rem 0"} pb={"3rem"} gap={"1.3rem"}>
              <Text>Are you sure you want to delete this user?</Text>
              <HStack>
                <Button
                  rounded={"0.18rem"}
                  h={"auto"}
                  p={"0.45rem 1rem"}
                  border={"1px solid #0158DE"}
                  color={"#0158DE"}
                  variant="outline"
                  onClick={() => setDeleteModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  rounded={"0.18rem"}
                  h={"auto"}
                  p={"0.45rem"}
                  // w={"10.8rem"}
                  // bgColor={"#0158DE"}
                  // color={"white"}
                  colorScheme="red"
                  variant="outline"
                  onClick={handleDelete}
                  leftIcon={<MdDelete />}
                >
                  Confirm
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* Add user modal  */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <VStack gap={"1rem"}>
                <HStack>
                  <VStack align={"start"} gap={"0.2rem"} w={"50%"}>
                    <Text>First Name</Text>
                    <Input
                      required={true}
                      rounded={"0.1rem"}
                      onFocus={handleFocus}
                      onBlur={(event) => handleBlur(event, "Enter First name")}
                      type="text"
                      placeholder="Enter First name"
                      p={"0.3rem 0.5rem"}
                      h={"auto"}
                      name="firstName"
                      onChange={handleChange}
                    />
                  </VStack>
                  <VStack align={"start"} gap={"0.2rem"} w={"50%"}>
                    <Text>Last Name</Text>
                    <Input
                      required={true}
                      rounded={"0.1rem"}
                      onFocus={handleFocus}
                      onBlur={(event) => handleBlur(event, "Enter Last name")}
                      type="text"
                      p={"0.3rem 0.5rem"}
                      h={"auto"}
                      name="lastName"
                      placeholder="Enter Last name"
                      onChange={handleChange}
                    />
                  </VStack>
                </HStack>
                <VStack align={"start"} gap={"0.2rem"} w={"100%"}>
                  <Text>Email</Text>
                  <Input
                    required={true}
                    rounded={"0.1rem"}
                    type="email"
                    p={"0.3rem 0.5rem"}
                    onFocus={handleFocus}
                    onBlur={(event) => handleBlur(event, "Enter Email address")}
                    h={"auto"}
                    placeholder="Enter Email address"
                    name="email"
                    onChange={handleChange}
                  />
                </VStack>
                <VStack align={"start"} gap={"0.2rem"} w={"100%"}>
                  <Text>Company</Text>
                  <Input
                    required={true}
                    rounded={"0.1rem"}
                    type="text"
                    p={"0.3rem 0.5rem"}
                    h={"auto"}
                    onFocus={handleFocus}
                    onBlur={(event) => handleBlur(event, "Enter Company")}
                    placeholder="Enter Company"
                    name="company"
                    onChange={handleChange}
                  />
                </VStack>
                <Button
                  rounded={"0.18rem"}
                  h={"auto"}
                  p={"0.5rem"}
                  mt={"1rem"}
                  w={"100%"}
                  type="submit"
                  bgColor={"#0158DE"}
                  color={"white"}
                >
                  Create User
                </Button>
              </VStack>
            </form>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      {/* Edit user modal  */}
      <Modal isOpen={editModal} onClose={() => setEditModal(false)}>
        <ModalOverlay />
        <ModalContent mt={"1.8rem !important"} mb={"0 !important"}>
          <ModalHeader>Edit User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleEdit}>
              <VStack gap={"1rem"}>
                <HStack>
                  <VStack align={"start"} gap={"0.2rem"} w={"50%"}>
                    <Text>First Name</Text>
                    <Input
                      required={true}
                      rounded={"0.1rem"}
                      onFocus={handleFocus}
                      onBlur={(event) => handleBlur(event, "Enter First name")}
                      type="text"
                      placeholder="Enter First name"
                      p={"0.3rem 0.5rem"}
                      h={"auto"}
                      name="firstName"
                      defaultValue={currentData.username?.split(" ")[0]}
                      ref={editFirstNameRef}
                    />
                  </VStack>
                  <VStack align={"start"} gap={"0.2rem"} w={"50%"}>
                    <Text>Last Name</Text>
                    <Input
                      required={true}
                      rounded={"0.1rem"}
                      onFocus={handleFocus}
                      onBlur={(event) => handleBlur(event, "Enter Last name")}
                      type="text"
                      p={"0.3rem 0.5rem"}
                      h={"auto"}
                      name="lastName"
                      placeholder="Enter Last name"
                      defaultValue={currentData.username?.split(" ")[1]}
                      ref={editLastNameRef}
                    />
                  </VStack>
                </HStack>
                <VStack align={"start"} gap={"0.2rem"} w={"100%"}>
                  <Text>Email</Text>
                  <Input
                    required={true}
                    rounded={"0.1rem"}
                    type="email"
                    p={"0.3rem 0.5rem"}
                    onFocus={handleFocus}
                    onBlur={(event) => handleBlur(event, "Enter Email address")}
                    h={"auto"}
                    placeholder="Enter Email address"
                    name="email"
                    defaultValue={currentData.email}
                    ref={editEmailRef}
                  />
                </VStack>
                <VStack align={"start"} gap={"0.2rem"} w={"100%"}>
                  <Text>Password</Text>
                  <Input
                    required={true}
                    rounded={"0.1rem"}
                    type="text"
                    p={"0.3rem 0.5rem"}
                    onFocus={handleFocus}
                    onBlur={(event) => handleBlur(event, "Enter Password")}
                    h={"auto"}
                    placeholder="Enter Password"
                    defaultValue={currentData.password}
                    ref={editPasswordRef}
                  />
                </VStack>
                <VStack align={"start"} gap={"0.2rem"} w={"100%"}>
                  <Text>Company</Text>
                  <Input
                    required={true}
                    rounded={"0.1rem"}
                    type="text"
                    p={"0.3rem 0.5rem"}
                    h={"auto"}
                    onFocus={handleFocus}
                    onBlur={(event) => handleBlur(event, "Enter Company")}
                    placeholder="Enter Company"
                    name="company"
                    defaultValue={currentData.company}
                    ref={editCompanyRef}
                  />
                </VStack>
                <Button
                  rounded={"0.18rem"}
                  h={"auto"}
                  p={"0.5rem"}
                  mt={"1rem"}
                  w={"100%"}
                  type="submit"
                  bgColor={"#0158DE"}
                  color={"white"}
                >
                  Update User
                </Button>
              </VStack>
            </form>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Users;
