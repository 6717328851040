import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
console.log(BASE_URL);
export const LoginAPI = (data) => {
  return axios.post(`${BASE_URL}/user/login`, data);
};
export const CheckProfileAPI = (token) => {
  return axios.get(`${BASE_URL}/user/profile`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const SuperAdminCreateNewUser = (token, data) => {
  return axios.post(`${BASE_URL}/superadmin/create-user`, data, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const SuperAdminGetAllUser = (token) => {
  return axios.get(`${BASE_URL}/superadmin/allusers`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const SuperAdminDeleteUser = (token, id) => {
  return axios.delete(`${BASE_URL}/superadmin/user/${id}`, {
    headers: {
      authorization: `${token}`,
    },
  });
};

export const SuperAdminEditUser = (token, data, id) => {
  return axios.patch(`${BASE_URL}/superadmin/user/${id}`, data, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const SuperAdminUserAllData = (token, filter = "all",sortBy,value) => {
  return axios.get(`${BASE_URL}/superadmin/alldata?filter=${filter}&sortBy=${sortBy}&value=${value}`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const SuperAdminAllCompanies = (token) => {
  return axios.get(`${BASE_URL}/superadmin/allcompanies/`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const SuperAdminUploadFile = (token, data, id) => {
  return axios.patch(`${BASE_URL}/superadmin/upload-file/${id}`, data, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const SuperAdminUpdateStatus = (token, data, id) => {
  return axios.patch(`${BASE_URL}/superadmin/update-status/${id}`, data, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const UserUploadFile = (token, data) => {
  return axios.post(`${BASE_URL}/data/upload-file/`, data, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const ClilentStaticData = (token,fromDate,toDate,whom) => {
  return axios.get(`${BASE_URL}/data/static?fromDate=${fromDate}&toDate=${toDate}&whom=${whom}`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const SuperAdminStaticData = (token,fromDate,toDate,company='all',whom='client') => {
  console.log(company,'checking');
  return axios.get(`${BASE_URL}/superadmin/static?fromDate=${fromDate}&toDate=${toDate}&company=${company}&whom=${whom}`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const UserAllData = (token, sortBy, value) => {
  return axios.get(`${BASE_URL}/data/alldata?sortBy=${sortBy}&value=${value}`, {
    headers: {
      authorization: `${token}`,
    },
  });
};
export const UpdatePassword = (token, data) => {
  return axios.post(`${BASE_URL}/user/changepassword`, data,{
    headers: {
      authorization: `${token}`,
    },
  });
};
export const DownloadUserFile = (token, id, fileName, who = "client") => {
  return axios({
    url: `${BASE_URL}/data/download-${
      who === "client" ? "client" : "admin"
    }/${id}`,
    method: "GET",
    headers: {
      authorization: `${token}`,
    },
  });
};
export const SuperAdminDownloadFile = (token, id, fileName) => {
  return axios({
    url: `${BASE_URL}/superadmin/download/${id}`,
    method: "GET",
    headers: {
      authorization: `${token}`,
    },
  });
  return axios({
    url: `${BASE_URL}/superadmin/download/${id}`,
    method: "GET",
    responseType: "blob",
    headers: {
      authorization: `${token}`,
    },
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return response;
    })
    .catch((error) => {
      // Handle error
      console.log(error, "erorr saying");
      return error;
    });
};
