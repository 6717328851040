import { Box, HStack, Input, Select, Text, VStack } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import ClientMiniCard from "./ClientMiniCard";
import { AppContext } from "../context/AppContext";
import { ClilentStaticData } from "../utils/api";
import CustomDatePicker from "./CustomDatePicker";

const ClientDashboardCard = () => {
  const fromDateRef = useRef();
  const toDateRef = useRef();
  const [companyName,setCompanyName] = useState('');
  const [data, setData] = useState({
    totalNumberOfRowsUploaded: 0,
    totalNumberOfRowsReceived: 0,
    totalNumberOfRowsInProgress: 0,
  });
  const [filter, setFilter] = useState({
    from: "",
    to: "",
    whom:'mydata'
  });
  const { Token1, handleLogout } = useContext(AppContext);
  useEffect(() => {
    let firstDate = new Date();
    let lastDate = new Date();
    // console.log((firstDate.getMonth()+1).toString().padStart(2,'0'));
    let formatedDate = `${firstDate.getFullYear()}-${(firstDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-01`;
    lastDate.setMonth(lastDate.getMonth() + 1);
    lastDate.setDate(1);
    lastDate.setDate(lastDate.getDate() - 1);
    fromDateRef.current.value = formatedDate;
    toDateRef.current.valueAsDate = lastDate;
    setFilter({
      from: fromDateRef.current.value,
      to: toDateRef.current.value,
      whom:'mydata'
    });
  }, []);
  useEffect(() => {
    let fromDate = filter.from.length
      ? filter.from
      : fromDateRef.current.value;
    let toDate = filter.to.length
      ? filter.to
      : toDateRef.current.value;
    if (filter.from.length) {
      ClilentStaticData(Token1, fromDate, toDate,filter.whom)
        .then((res) => {
          if(companyName==''){
            setCompanyName(res.data.company);
          }
          let uploaded = res.data.data?.totalNumberOfRowsUploaded;
          if (uploaded) {
            let received = res.data.data.totalNumberOfRowsReceived;
            setData({
              totalNumberOfRowsUploaded: uploaded,
              totalNumberOfRowsReceived: received,
              totalNumberOfRowsInProgress: uploaded - received,
            });
          } else {
            setData({
              totalNumberOfRowsInProgress: 0,
              totalNumberOfRowsUploaded: 0,
              totalNumberOfRowsReceived: 0,
            });
          }
        })
        .catch((err) => {
          handleLogout(err);
        });
    }
  }, [filter]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilter({
      ...filter,
      [name]: value,
    });
  };
  return (
    <VStack
      w={"85%"}
      gap={"2rem"}
      pt={"3rem"}
      position={"relative"}
      align={"start"}
    >
      <HStack>
      <VStack gap={"0"} align={"start"}>
            <Text fontSize={"0.9rem"}>Select Data</Text>
            <Select
              cursor={"pointer"}
              onChange={handleChange}
              w={"13rem"}
              h={"1.95rem"}
              name={"whom"}
              fontSize={"0.9rem"}
            >
              <option value="mydata">My data</option>
              <option value="company">{companyName||'company'}</option>
            </Select>{" "}
          </VStack>
        <VStack gap={"0"} align={"start"}>
          <Text fontSize={"0.9rem"}>From</Text>
          <CustomDatePicker
            parentRef={fromDateRef}
            name={"from"}
            handleChange={handleChange}
          />
        </VStack>
        <VStack gap={"0"} align={"start"}>
          <Text fontSize={"0.9rem"}>To</Text>
          <CustomDatePicker
            parentRef={toDateRef}
            name={"to"}
            handleChange={handleChange}
          />
        </VStack>
      </HStack>
      <HStack w={"100%"} gap={"2rem"}>
        <ClientMiniCard
          color={"black"}
          digits={data.totalNumberOfRowsUploaded}
          title={"Rows Uploaded"}
        />
        <ClientMiniCard
          color={"#249620"}
          digits={data.totalNumberOfRowsReceived}
          title={"Rows Received"}
        />
        <ClientMiniCard
          color={"#FA8A0E"}
          digits={data.totalNumberOfRowsInProgress}
          title={"Rows in Progress"}
        />
      </HStack>
    </VStack>
  );
};

export default ClientDashboardCard;