import logo from './logo.svg';
import './App.css';
import AllPages from './pages/AllPages';

function App() {
  return (
    <div className="App">
        <AllPages/>
    </div>
  );
}

export default App;
