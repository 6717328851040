import React, { useContext, useState, useEffect } from "react";
import { Button, Input, Text, VStack, useToast } from "@chakra-ui/react";
import { LoginAPI } from "../../utils/api";
import { AppContext } from "../../context/AppContext";
import { useNavigate, Navigate } from "react-router-dom";
const Login = () => {
  const toast = useToast();
  const { setIsSuperAdmin, setIsAuth, isAuth, isSuperAdmin } =
    useContext(AppContext);
  const redirect = useNavigate();
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    // console.log(isSuperAdmin, isAuth, "hello world");
  }, [isSuperAdmin, isAuth]);
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(data, "checking data");
    LoginAPI(data)
      .then((res) => {
        toast({
          status: "success",
          title: res.data.message,
        });
        if (res.data.role === "USER") {
          setIsSuperAdmin(false);
          setIsAuth(true);
          localStorage.setItem("accessToken", res.data.token);
          redirect("/");
        } else if (res.data.role === "SUPER_ADMIN") {
          setIsSuperAdmin(true);
          setIsAuth(false);
          localStorage.setItem("AccessToken", res.data.token);
          redirect("/manage/users");
        }
      })
      .catch((err) => {
        toast({
          title: err.response?.data?.message
            ? err.response?.data.message
            : err.message,
          status: "error",
          duration: 2500,
          isClosable: true,
          position: "bottom",
        });
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: name == "email" ? value.toLowerCase() : value,
    });
  };
  if (isAuth) {
    return <Navigate to="/" />;
  }
  if (isSuperAdmin) {
    return <Navigate to="/manage"   />;
  }
  return (
    <VStack
      align={"center"}
      justify={"center"}
      bgColor={"#0158DE"}
      w={"100%"}
      h={"100vh"}
    >
      <form
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
        onSubmit={handleSubmit}
      >
        <VStack
          gap={"1.7rem"}
          rounded={"0.28rem"}
          minH={"40vh"}
          w={"26%"}
          bgColor={"white"}
          boxSizing={"border-box"}
          p={"2rem 1.5rem"}
        >
          <VStack align={"start"} gap={"0.1rem"} w={"90%"}>
            <Text>Email</Text>
            <Input
              required={true}
              rounded={"0.28rem"}
              type="email"
              p={"0.2rem 0.5rem"}
              h={"auto"}
              name="email"
              onChange={handleChange}
            />
          </VStack>
          <VStack align={"start"} gap={"0.1rem"} w={"90%"}>
            <Text>Password</Text>
            <Input
              required={true}
              rounded={"0.28rem"}
              type="password"
              p={"0.2rem 0.5rem"}
              h={"auto"}
              name="password"
              onChange={handleChange}
              autoComplete="false"
            />
          </VStack>
          <Button
            my={"2rem"}
            fontSize={"1.1rem"}
            fontWeight={500}
            rounded={"0.28rem"}
            variant="outline"
            type="submit"
            w={"57%"}
            p={"0.22rem"}
            h={"auto"}
            colorScheme="telegram"
          >
            Login
          </Button>
        </VStack>
      </form>
    </VStack>
  );
};

export default Login;
