import React, { useContext, useEffect, useState } from "react";
import styles from "./UploadFile.module.css";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { UserUploadFile } from "../utils/api";
import { AppContext } from "../context/AppContext";
import Lottie from "lottie-react";
import successAnimation from "../animation/successAnimation.json";
const UploadFile = ({ handleNewData }) => {
  const { Token1, handleLogout } = useContext(AppContext);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const handleChange = (event) => {
    let file = event.target.files[0];
    let formData = new FormData();
    formData.append("excel", file);
    onOpen();
    setLoading(true);
    if (file != undefined) {
      UserUploadFile(Token1, formData)
        .then((res) => {
          setLoading(false);
          handleNewData(res.data.data);
          setTimeout(() => {
            onClose();
          }, 1500);
          // toast({
          //   status: "success",
          //   title: res.data.message,
          //   isClosable: true,
          //   duration: 2000,
          // });
        })
        .catch((err) => {
          onClose();
          setLoading(true);
          handleLogout(err);
        });
    }
  };
  return (
    <VStack  w={"100%"} position={"sticky"} top={"3rem"} h={"8rem"}>
      <input
        style={{ display: "none" }}
        type="file"
        id="my_excel"
        // multiple
        name="excel"
        onChange={handleChange}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <label className={styles.upload} htmlFor="my_excel">
        Click here to Upload Files <span>Max size 50mb</span>
      </label>
      <Modal closeOnOverlayClick={false}  isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width={"15rem"} height={"15rem"}>
          <ModalBody height={"100%"}>
            <VStack
              height={"100%"}
              align={"center"}
              width={"100%"}
              justify={"center"}
            >
              {loading ? (
                <Spinner
                  speed="0.35s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size={"xl"}
                  thickness='4.5px'
                />
              ) : (
                <Lottie
                  animationData={successAnimation}
                  style={{ width: "100%" }}
                  loop={true}
                />
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default UploadFile;
