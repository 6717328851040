import {
  HStack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import {
  AiOutlineArrowDown,
  AiOutlineArrowRight,
  AiOutlineArrowUp,
} from "react-icons/ai";
import LeftSideBar from "../../components/LeftSideBar";
import UploadFile from "../../components/UploadFile";
import { UserAllData } from "../../utils/api";
import { AppContext } from "../../context/AppContext";
import ClientDataCard from "../../components/ClientDataCard";
const ClilentData = () => {
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const toast = useToast();
  const [sort, setSort] = useState({ name: "createdAt", value: -1 });
  const [statusSort, setStatusSort] = useState("down");
  const [dateSort, setDateSort] = useState("down");
  const [nameSort, setNameSort] = useState("atoz");
  const { Token1, handleLogout } = useContext(AppContext);
  useEffect(() => {
    setLoading(true);
    UserAllData(Token1, sort.name, sort.value)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        handleLogout(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [sort]);
  const handleSort = (name, value, position) => {
    setSort({ ...sort, name, value });
    if (name === "createdAt") {
      setDateSort(position);
    } else if (name === "status") {
      setStatusSort(position);
    } else if (name == "clientFileName") {
      setNameSort(position);
    }
  };
  const handleNewData = (newdata) => {
    let mydata = {
      ...newdata.newData,
      userId: newdata.userId,
    };
    setData([{ ...mydata }, ...data]);
  };
  return (
    <VStack
      w={"80%"}
      gap={"2rem"}
      pt={"3rem"}
      position={"relative"}
      align={"end"}
    >
      <UploadFile handleNewData={handleNewData} />
      <TableContainer w={"100%"} h={"60vh"} overflowY={"auto"}>
        <Table size="sm">
          <Thead position={"sticky"} top={"0"} background={"white"} zIndex={10}>
            <Tr>
              <Th
                bgColor={"#baa8ff"}
                textTransform={"none"}
                fontSize={"0.9rem"}
              >
                <HStack gap={"2rem"} w={"13rem"}>
                  <Text>File Name</Text>
                  <HStack gap={0}>
                    <Text>{nameSort == "atoz" ? "A" : "Z"}</Text>
                    <AiOutlineArrowRight
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        nameSort === "atoz"
                          ? handleSort("clientFileName", 1, "ztoa")
                          : handleSort("clientFileName", -1, "atoz")
                      }
                    />
                    <Text>{nameSort == "atoz" ? "Z" : "A"}</Text>
                  </HStack>
                </HStack>
              </Th>
              <Th
                bgColor={"#baa8ff"}
                textTransform={"none"}
                fontSize={"0.9rem"}
              >
                <Text minW={"4rem"}>Rows</Text>
              </Th>
              <Th
                bgColor={"#baa8ff"}
                textTransform={"none"}
                fontSize={"0.9rem"}
              >
                <HStack w={"8rem"}>
                  <Text>
                    {" "}
                    Upload date <br />
                    (IST)
                  </Text>
                  {dateSort == "down" ? (
                    <AiOutlineArrowDown
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleSort("createdAt", 1, "up")}
                    />
                  ) : (
                    <AiOutlineArrowUp
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleSort("createdAt", -1, "down")}
                    />
                  )}
                </HStack>
              </Th>
              <Th
                bgColor={"#baa8ff"}
                textTransform={"none"}
                w={"12rem"}
                fontSize={"0.9rem"}
              >
                Uploaded by
              </Th>
              <Th
                bgColor={"#45c584"}
                color={"black"}
                textTransform={"none"}
                w={"10rem"}
                fontSize={"0.8rem"}
              >
                Rows <br />
                (Received / uploaded)
              </Th>
              <Th
                bgColor={"#45c584"}
                color={"black"}
                textTransform={"none"}
                w={"10rem"}
                fontSize={"0.8rem"}
              >
                <HStack w={"9rem"}>
                  <Text>Status</Text>{" "}
                  {statusSort == "down" ? (
                    <AiOutlineArrowDown
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleSort("status", 1, "up")}
                    />
                  ) : (
                    <AiOutlineArrowUp
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleSort("status", -1, "down")}
                    />
                  )}
                </HStack>
              </Th>
            </Tr>
          </Thead>
          <Tbody bgColor={"#F8F8F8"}>
            {!Loading &&
              data?.map((el) => (
                <ClientDataCard
                  key={el._id}
                  clientFileLink={el.clientFileLink}
                  email={el.userId.email}
                  superAdminFileLink={el.superAdminFileLink}
                  clientFileName={el.clientFileName}
                  date={el.date}
                  id={el._id}
                  NumberOfRowsClient={el.NumberOfRowsClient}
                  NumberOfRowsAdmin={el.NumberOfRowsAdmin}
                  status={el.status}
                  superAdminFileName={el.superAdminFileName}
                />
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      {Loading && (
        <Spinner
          position={"absolute"}
          top={"66%"}
          left={"50%"}
          color="grey"
          size="lg"
        />
      )}
    </VStack>
  );
};

export default ClilentData;
