import React, { useContext, useEffect, useState } from 'react'
import {Alert, AlertDescription, AlertIcon, AlertTitle, Button, HStack, Input, Modal, ModalBody, ModalContent, ModalOverlay, Text, VStack, useDisclosure, useToast} from '@chakra-ui/react';
import { UpdatePassword } from '../../utils/api';
import { AppContext } from '../../context/AppContext';
import Lottie from "lottie-react";
import successAnimation from '../../animation/successAnimation.json';
const ClientSettings = () => {
    const {isOpen,onClose,onOpen} = useDisclosure();
    const toast = useToast();
    const [error,setError] = useState('');
    const {Token1} = useContext(AppContext);
    const [password,setPassword] = useState({
        currentpassword:'',
        newpassword:''
    });
    const handleChangePassword = (event)=>{
        event.preventDefault();
        UpdatePassword(Token1,password).then((res)=>{
            // toast({
            //     status:'success',
            //     title:res.data.message,
            //     isClosable:true,
            //     duration:1000
            // });
            onOpen();
            setTimeout(() => {
                onClose();
            }, 1500);
        }).catch((err)=>{
            setError(err.response?.data?.message);
            // toast({
            //     title: err.response?.data?.message
            //       ? err.response?.data.message
            //       : err.message,
            //     status: "error",
            //     duration: 1000,
            //     isClosable: true,
            //     position: "bottom",
            //   });
        })
        console.log(password,'hello');
    };
    const handleChange = (event)=>{
        const {name,value} = event.target;
        if(name=='currentpassword'&&error.length){
            setError('');
        }
        setPassword({...password,[name]:value});
    }

  return (
    <VStack
    w={"80%"}
    gap={"2rem"}
    pt={"3rem"}
    // position={"relative"}
    align={"start"}>
        <VStack>

        
        <form onSubmit={handleChangePassword} >
            <HStack align={"end"}>
            <VStack  gap={"0"} align={"start"}>
                     <Text fontSize={"0.9rem"}>Current password</Text>
                    <Input minLength={8} maxLength={20} required h={'auto'} p={'0.3rem 0.5rem'} onChange={handleChange} name='currentpassword' placeholder='current password'/>
                </VStack>
                <VStack gap={"0"} align={"start"}>
                <Text fontSize={"0.9rem"}>New password</Text>
                    <Input minLength={8}  maxLength={20}required h={'auto'} p={'0.3rem 0.5rem'} onChange={handleChange} name='newpassword' placeholder='new password'/>
                </VStack>
                <Button h={'2.2rem'} color={'white'}  bgColor={'#2F78E4'} type='submit'>Update Password</Button>
            </HStack>
        </form>
        {
        error.length&&
        <Alert status='error'>
            <AlertIcon />
            <AlertTitle>{error}</AlertTitle>
        </Alert>
        }
        </VStack>
        <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent width={'15rem'} height={'15rem'}>
          <ModalBody>
                <VStack align={'center'} width={'100%'} justify={'center'}>
              <Lottie animationData={successAnimation} style={{width:'100%'}} loop={true} />
                </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  )
}

export default ClientSettings;