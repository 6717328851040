import { HStack,Input,Box } from "@chakra-ui/react";
import React from "react";
const  month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];

const convertDate = (date)=>{
    date = date.split("-");
    let m = date[1];
    let y = date[0];
    let d = date[2];
    return `${d}-${month[m-1]}-${y}`;
}
const CustomDatePicker = ({ parentRef, handleChange,name }) => {
  return (
    <HStack position={"relative"}>
      <Input
        fontSize={"0.9rem"}
        ref={parentRef}
        color={"transparent"}
        name={name}
        onChange={handleChange}
        placeholder="Select Date and Time"
        size="md"
        type="date"
        h={"auto"}
        py={"0.2rem"}
      />{" "}
      <Box zIndex={10000} background={'white'} left={'1rem'} position={"absolute"} fontSize={"0.9rem"} as="span">
        {parentRef.current?.value && convertDate(parentRef.current?.value) }
      </Box>
    </HStack>
  );
};

export default CustomDatePicker;
