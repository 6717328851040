import { Text, VStack } from "@chakra-ui/react";
import React from "react";

const ClientMiniCard = ({ color = "black", digits, title }) => {
  return (
    <VStack
      justify={"space-between"}
      bg={"#F2F1F2"}
      p={"0.2rem"}
      minH={"9rem"}
      w={"30%"}
    >
      <Text color={color} mt={"1rem"} fontSize={"2.5rem"} fontWeight={700}>
        {digits.toLocaleString()}
      </Text>
      <Text fontWeight={600} mb={"0.5rem"}>
        {title}
      </Text>
    </VStack>
  );
};

export default ClientMiniCard;
