import {
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import ClientMiniCard from "../../../components/ClientMiniCard";
import { AppContext } from "../../../context/AppContext";
import {
  SuperAdminAllCompanies,
  SuperAdminStaticData,
} from "../../../utils/api";
import CustomDatePicker from "../../../components/CustomDatePicker";
// import Select from "react-select";

const Dashboard = () => {
  const fromDateRef = useRef();
  const toDateRef = useRef();
  const [compnaies, setCompanies] = useState([]);
  const [data, setData] = useState({
    totalNumberOfRowsUploaded: 0,
    totalNumberOfRowsReceived: 0,
    totalNumberOfRowsInProgress: 0,
  });
  const [whom, setWhom] = useState("client");
  const [filter, setFilter] = useState({
    from: "",
    to: "",
    company: "all",
  });
  const { Token2, handleLogout } = useContext(AppContext);
  useEffect(() => {
    let firstDate = new Date();
    let lastDate = new Date();
    // console.log((firstDate.getMonth()+1).toString().padStart(2,'0'));
    let formatedDate = `${firstDate.getFullYear()}-${(firstDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-01`;
    lastDate.setMonth(lastDate.getMonth() + 1);
    lastDate.setDate(1);
    lastDate.setDate(lastDate.getDate() - 1);
    fromDateRef.current.value = formatedDate;
    toDateRef.current.valueAsDate = lastDate;
    setFilter({
      from: fromDateRef.current.value,
      to: toDateRef.current.value,
    });
    SuperAdminAllCompanies(Token2)
      .then((res) => {
        setCompanies(res.data.data);
      })
      .catch((err) => {
        handleLogout(err);
      });
  }, []);
  useEffect(() => {
    let fromDate = filter.from.length ? filter.from : fromDateRef.current.value;
    let toDate = filter.to.length ? filter.to : toDateRef.current.value;
    if (filter.from.length) {
      SuperAdminStaticData(
        Token2,
        fromDate,
        toDate,
        filter.company || "all",
        whom
      )
        .then((res) => {
          let uploaded = res.data.data?.totalNumberOfRowsUploaded;
          if (uploaded) {
            let received = res.data.data.totalNumberOfRowsReceived;
            setData({
              totalNumberOfRowsUploaded: uploaded,
              totalNumberOfRowsReceived: received,
              totalNumberOfRowsInProgress: uploaded - received,
            });
          } else {
            setData({
              totalNumberOfRowsInProgress: 0,
              totalNumberOfRowsUploaded: 0,
              totalNumberOfRowsReceived: 0,
            });
          }
        })
        .catch((err) => {
          handleLogout(err);
        });
    }
  }, [filter, whom]);
  const handleChange = (event) => {
    if (event.target.name === "whom") {
      setWhom(event.target.checked ? "admin" : "client");
    } else {
      const { name, value } = event.target;
      setFilter({
        ...filter,
        [name]: value,
      });
    }
  };
  return (
    <VStack w={"100%"} gap={"2rem"} position={"relative"} align={"start"}>
      <HStack w={"100%"} justify={"space-between"}>
        <HStack>
          <VStack gap={"0"} align={"start"}>
            <Text fontSize={"0.9rem"}>Client</Text>
            <Select
              cursor={"pointer"}
              onChange={handleChange}
              w={"13rem"}
              h={"1.95rem"}
              placeholder="All Company"
              name={"company"}
              fontSize={"0.9rem"}
            >
              {compnaies?.map((el) => (
                <option key={el} value={el}>
                  {el}
                </option>
              ))}{" "}
            </Select>{" "}
          </VStack>
          <VStack gap={"0"} align={"start"}>
            <Text fontSize={"0.9rem"}>From</Text>
            <CustomDatePicker
              parentRef={fromDateRef}
              name={"from"}
              handleChange={handleChange}
            />
          </VStack>
          <VStack gap={"0"} align={"start"}>
            <Text fontSize={"0.9rem"}>To</Text>
            <CustomDatePicker
              parentRef={toDateRef}
              name={"to"}
              handleChange={handleChange}
            />
          </VStack>
        </HStack>
        <HStack>
          <Text fontSize={"0.97rem"} mr={"0.5rem"}>
            View as:
          </Text>
          <Text
            fontWeight={600}
            color={whom == "client" ? "#2F78E4" : "#b9b3b3"}
            fontSize={"0.97rem"}
          >
            Client
          </Text>
          <Switch name="whom" onChange={handleChange} />
          <Text
            fontWeight={600}
            color={whom == "admin" ? "#2F78E4" : "#b9b3b3"}
            fontSize={"0.97rem"}
          >
            Admin
          </Text>
        </HStack>
      </HStack>
      <HStack w={"100%"} gap={"2rem"}>
        {whom == "client" && (
          <ClientMiniCard
            color={"black"}
            digits={data.totalNumberOfRowsUploaded}
            title={"Rows received from customer"}
          />
        )}
        <ClientMiniCard
          color={"#249620"}
          digits={data.totalNumberOfRowsReceived}
          title={"Rows sent to customer"}
        />
        <ClientMiniCard
          color={"#FA8A0E"}
          digits={data.totalNumberOfRowsInProgress}
          title={"Rows in Progress"}
        />
      </HStack>
    </VStack>
  );
};

export default Dashboard;
