import { HStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import LeftSideBar from "../../components/LeftSideBar";
import { Outlet, useLocation } from "react-router-dom";
import ClientDashboardCard from "../../components/ClientDashboardCard";
import { GoDatabase } from "react-icons/go";
import {FiSettings} from 'react-icons/fi';
const LeftBarData = [
  { name: "Dashboard", link: "/" },
  { name: "Data", link: "/data",Icon:GoDatabase },
  { name: "Setting", link: "/setting",Icon:FiSettings },
];
const ClientDashboard = () => {
  const { pathname } = useLocation();

  return (
    <HStack minH={"100vh"} gap={"1.8rem"} align={"start"}>
      <LeftSideBar LeftBarData={LeftBarData} />
      {pathname === "/" && <ClientDashboardCard />}
      <Outlet />
    </HStack>
  );
};

export default ClientDashboard;
