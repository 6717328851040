import { Tr, Td, Text } from "@chakra-ui/react";
import React from "react";

const UsersCard = ({
  username,
  company,
  email,
  password,
  handleModals,
  id,
  el,
}) => {
  return (
    <Tr>
      <Td>{company}</Td>
      <Td>{username}</Td>
      <Td>{email}</Td>
      <Td>{password}</Td>
      <Td>
        <Text
          cursor={"pointer"}
          color={"#0158DE"}
          onClick={() => handleModals(id, "edit", el)}
        >
          Edit
        </Text>
      </Td>
      <Td>
        <Text
          cursor={"pointer"}
          color={"#F21F1F"}
          onClick={() => handleModals(id, "delete")}
        >
          Delete
        </Text>
      </Td>
    </Tr>
  );
};

export default UsersCard;
