import { createContext, useEffect, useState } from "react";
import { CheckProfileAPI } from "../utils/api";
import { useToast } from "@chakra-ui/react";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const Token1 = localStorage.getItem("accessToken") || null;
  const Token2 = localStorage.getItem("AccessToken") || null;
  const [isSuperAdmin, setIsSuperAdmin] = useState(Token2 ? true : false);
  const [isAuth, setIsAuth] = useState(Token1 ? true : false);
  const toast = useToast();
  const handleLogout = (err = {}) => {
    if (err.response?.data?.message === "invalid signature") {
      if (isAuth) {
        localStorage.removeItem("accessToken");
        setIsAuth(false);
      } else if (isSuperAdmin) {
        setIsSuperAdmin(false);
        localStorage.removeItem("AccessToken");
      }
      toast({
        title: "Logout successfully",
        status: "success",
        position: "top-right",
        isClosable: true,
        duration: 1500,
      });
    }
    toast({
      title: err.response?.data?.message
        ? err.response?.data.message
        : err.message,
      status: "error",
      duration: 2500,
      isClosable: true,
      position: "bottom",
    });
  };
  const checkAuth = () => {
    // CheckProfileAPI(Token)
    //   .then((res) => {
    //     if (res.data.role == "USER") {
    //       setIsAuth(true);
    //     } else if (res.data.role == "SUPER_ADMIN") {
    //       console.log("inside this");
    //       setIsSuperAdmin(true);
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.response?.data?.message === "invalid signature") {
    //       handleLogout();
    //     }
    //   });
  };
  useEffect(() => {
    // if (Token) {
    //   checkAuth();
    // }
  }, []);
  const contextValues = {
    isAuth,
    setIsAuth,
    isSuperAdmin,
    setIsSuperAdmin,
    Token1,
    Token2,
    handleLogout,
  };
  return (
    <AppContext.Provider value={contextValues}>{children}</AppContext.Provider>
  );
};
