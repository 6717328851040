import { HStack, Text, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
const LeftSideBar = ({ LeftBarData }) => {
  const redirect = useNavigate();
  const { isAuth, isSuperAdmin, setIsAuth, setIsSuperAdmin } =
    useContext(AppContext);
  const { pathname } = useLocation();
  const handleLogout = () => {
    if (isAuth) {
      localStorage.removeItem("accessToken");
      setIsAuth(false);
    } else if (isSuperAdmin) {
      setIsSuperAdmin(false);
      localStorage.removeItem("AccessToken");
    }
  };
  return (
    <VStack
      borderRight={"2px solid #F8F8F8"}
      h={"100vh"}
      position={"sticky"}
      left={"0"}
      top={"0"}
      w={"10%"}
      justify={"space-between"}
      py={"2rem"}
      align={"center"}
    >
      <VStack gap={"0rem"} w={"100%"} align={"start"} mt={"5rem"}>
        {LeftBarData.map((el, index) => (
          <HStack
            key={el.name + el.link + index}
            pl={"1rem"}
            py={"5px"}
            w={"100%"}
            cursor={"pointer"}
            onClick={() => redirect(el.link)}
            style={
              pathname === el.link
                ? {
                    background: "#2F78E4",
                    color: "white",
                    fontWeight: 600,
                  }
                : { background: "rgb(227 222 222)", color: "black" }
            }
          >
            {
              el.Icon && (
                <el.Icon/>
              )
            }
            {/* {el.name == "Users" && (
              <AiOutlineUsergroupAdd style={{ fontSize: "1.4rem" }} />
            )}
            {el.name == "Data" && (
              <GoDatabase
                style={
                  pathname === el.link
                    ? {
                        color: "white",
                      }
                    : {}
                }
              />
            )}
            {
              el.name=='Setting'&&(
                <New  style={
                  pathname === el.link
                    ? {
                        color: "white",
                      }
                    : {}
                }/>
              )
            } */}
            <Text key={el.name} fontSize={"0.9rem"}>
              {el.name}
            </Text>
          </HStack>
        ))}
      </VStack>
      <Text cursor={"pointer"} onClick={handleLogout}>
        Logout
      </Text>
    </VStack>
  );
};

export default LeftSideBar;
